import React from "react";

import "../styles.css";
import flower from "../images/flower.png";
import flier from "../images/Jan2025/Jan26.png";

// function Quote() {
//   return (
//     <div className="quote">
//       <div className="main-container">
//         <img src={flower} alt="bg-banner" />
//         <div className="text-overlay">
//           <span>
//             There is always a BIG MATERIAL WORLD in the mind .. it indeed is a
//             very, very BIG WORLD. Emptying the BIG MATERIAL WORLD.. in the mind
//             .. is called MEDITATION.
//           </span>
//           <br />
//           <a href="https://www.pssmovement.org/about-patriji/">
//             <span>Brahmarshi Pitamaha Patriji</span>
//           </a>
//           {/* <div className="wish-msg">
//             <p>Atlanta Pyramid Meditation Academy Wishes you a very</p>
//             <h2> Happy birthday Patriji </h2>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// }

// function Quote() {
//   return (
//     <div className="quote">
//       <div className="main-container">
//         {/* <img src={flower} alt="bg-banner" /> */}
//         <div className="text-overlay">
//           <span>
//             There is always a BIG MATERIAL WORLD in the mind .. it indeed is a
//             very, very BIG WORLD. Emptying the BIG MATERIAL WORLD.. in the mind
//             .. is called MEDITATION.
//           </span>
//           <br />
//           <a href="https://www.pssmovement.org/about-patriji/">
//             <span>Brahmarshi Pitamaha Patriji</span>
//           </a>
//           {/* <div className="wish-msg">
//             <p>Atlanta Pyramid Meditation Academy Wishes you a very</p>
//             <h2> Happy birthday Patriji </h2>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// }

function Quote() {
  return (
    <div className="quote-main">
      <a href="https://bit.ly/sun-2025" target="_blank">
        {" "}
        <img className="quote-flier" src={flier} alt="Event Flier" />
      </a>

      <div className="event-details">
        <h2 className="event-title">
          🌟 Celebrating Sun Transistion - Group Meditation Event 🌟
        </h2>

        <div className="event-info">
          <p>
            <strong>Location:</strong> <a href="https://maps.app.goo.gl/WFACfnd8hrBhQbF58">56 Perimeter Center E #3d, Dunwoody, GA 30346</a>
          </p>
        </div>
        <p>
            <a href="https://bit.ly/sun-2025"><strong>Register Here</strong></a>
        </p>
        <p className="event-highlight">
          Around 200 Masters are expected in this event for Group Meditation
        </p>
        <p className="event-highlight">
          🎶 As the Sun transits to give us more longer days, lets do Group Meditaiton with <strong>Our Masters and New Participants!</strong>
        </p>
        <p className="event-highlight">
          🧘‍♂️ Lets welcome to energize mind, body and soul with deep meditation and spiritual rejuvenation in a
          serene environment.
        </p>
        <p className="event-highlight">👧🧒 Special activities for Kids.</p>
        <p>
            <a href="https://bit.ly/donate-apma"><strong>Donate Here</strong></a>
          </p>
      </div>
    </div>
  );
}

export default Quote;
