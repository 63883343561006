// File: FlyerPhotos.jsx
import React from "react";
import PropTypes from "prop-types";

import {
  LocalEventDetails,
  USA_EventDetails,
  eventData,
} from "../EventDetails";

const FlyerPhotos = ({ eventData }) => {
  const {
    date,
    title,
    location,
    host,
    description,
    count,
    mediaItems,
    fbLink,
    instaLink,
    youtubeLink,
    twitterLink,
    linkedinLink,
  } = eventData;

  return (
    <div className="event-container">
      <div className="event-details">
        <h2 className="event-title">{title}</h2>
        <p className="event-date">Date: {date}</p>
        <p className="event-location">Location: {location}</p>
        <p className="event-host">Host: {host}</p>
        <p className="event-description">{description}</p>
        <p className="event-count">Total Masters Attended: {count}</p>
        <div className="social-media">
          <a
            href={instaLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Instagram_font_awesome.svg/512px-Instagram_font_awesome.svg.png"
              alt="Instagram"
              className="social-icon"
            />
          </a>
          <a
            href={fbLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/f1/Ec-facebook.png"
              alt="Facebook"
              className="social-icon"
            />
          </a>
          <a
            href={twitterLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/X_logo_2023.svg/300px-X_logo_2023.svg.png"
              alt="Twitter"
              className="social-icon"
            />
          </a>
          <a
            href={linkedinLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Font_Awesome_5_brands_linkedin.svg/512px-Font_Awesome_5_brands_linkedin.svg.png"
              alt="LinkedIn"
              className="social-icon"
            />
          </a>
          <a
            href={youtubeLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/YouTube_social_dark_circle_%282017%29.svg/256px-YouTube_social_dark_circle_%282017%29.svg.png"
              alt="YouTube"
              className="social-icon"
            />
          </a>
        </div>
      </div>
      <div className="media-container">
        {mediaItems.map((item, index) => (
          <div key={index} className="media-item">
            {item.type === "flyer" ? (
              <img src={item.src} alt="Flyer" className="flyer-image" />
            ) : (
              // <img className="event-image" src={props.img} alt="event img" />
              <img
                src={item.src}
                alt={`Photo ${index}`}
                className="photo-image"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// PropTypes for type checking
FlyerPhotos.propTypes = {
  eventData: PropTypes.shape({
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    mediaItems: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
      })
    ).isRequired,
    instaLink: PropTypes.string.isRequired,
    fbLink: PropTypes.string.isRequired,
    youtubeLink: PropTypes.string.isRequired,
    twitterLink: PropTypes.string.isRequired,
    linkedinLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default FlyerPhotos;
