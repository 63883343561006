import React from "react";
import Program from "../components/Program";

import Quote from "../components/Quote";
import Events from "../components/Events";
import Donation from "../components/Donation";
import Volunteer from "../components/Volunteer";
import Footer from "../components/Footer";

import "../styles.css";
import AboutPatriji from "../components/AboutPatriji";
import Meditation from "../components/Meditation";
import Spiritual from "../components/spiritual";
import SocialMedia from "../components/SocialMedia";
import Inperson from "../components/Inperson";
import WhyMeditation from "../components/WhyMeditation";
import WhyPyramids from "../components/WhyPyramids";
import HowToMeditate from "../components/HowToMeditate";
import altZelle from "../images/atlZelle1.jpeg";
import altZeffy from "../images/icons/zeffy.png";

function DonationPage() {
  return (
    <div>
        <div className="donate-details">
        <p className="donate-details-heading">Donate</p>
        <div>
        <span className="side-heading">Zeffy: </span>
          <span className="side-value">
            <form
              action="https://www.zeffy.com/embed/donation-form/donate-to-make-a-difference-4600?modal=true"
              method="post"
              target="_top"
            >
            <input
                type="image"
                src={altZeffy} className="atlZelle" alt="Donate with Zeffy button" 
                border="0"
                name="submit"
                title="Donation form powered by Zeffy"
            />
            </form>
          </span>
        </div>
        <div>
          <span className="side-heading">PayPal: </span>
          <span className="side-value">
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_top"
            >
              <input type="hidden" name="campaign_id" value="X92BQN532L7XC" />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
            </form>
          </span>
        </div>
        {/* <div>
          <span className="side-heading">Venmo: </span>
          <span className="side-value"> PSSM USA</span>
        </div> */}
        <div className="zelle">
          <div>
            <span className="side-heading">Zelle: </span>
            <span className="side-value"> donate_apma@pssmusa.org</span>
          </div>
          <img src={altZelle} className="atlZelle" alt="zelle" />
        </div>

      </div>
    </div>
  );
}

export default DonationPage;
