const eventData2025 = [
  {
    id: 10,
    date: "Jan 26, 2025",
    title: "Sun Transit Meditation",
    host: "APMA Team",
    location: "Dunwoody, GA.",
    count: 100,
    description:
      "Another grand success! The Sun Transition Group Meditation Event in Dunwoody, GA, marked a milestone by opening a new meditation space in Atlanta. With over 130 participants, the event featured live flute-guided meditation and sound bath meditation, filling the space with joy and transformative energy.",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/sun/1.jpg") },
      { type: "photo", src: require("./images/Jan2025/sun/2.jpg") },
      { type: "photo", src: require("./images/Jan2025/sun/3.jpg") },
      { type: "photo", src: require("./images/Jan2025/sun/4.jpg") },
      { type: "photo", src: require("./images/Jan2025/sun/5.jpg") },
      { type: "photo", src: require("./images/Jan2025/sun/6.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DFUIVniuMBs",
    fbLink: "https://www.facebook.com/share/r/15biAE82cz",
    youtubeLink: "https://youtube.com/shorts/2tnKhLx_EWA",
    twitterLink: "http://x.com/atlpma/status/1883725275537768540",
    linkedinLink:
      "http://www.linkedin.com/posts/atlpma_groupmeditation-suntransitionevent-dunwoodyga-activity-7289491309424164864-8Q6i",
  },
  {
    id: 9,
    date: "Jan 25, 2025",
    title: "Meditation stall at the Pongal event by Atlanta Tamil Mandram (ATM)",
    host: "APMA Team",
    location: "Cumming, GA.",
    count: 15,
    description:
      "APMA hosted a meditation stall at the Pongal event by Atlanta Tamil Mandram, receiving an incredible and heartwarming response. More than 10 new masters learned the importance of Meditation and interested to practice!",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/25-4.jpg") },
      { type: "photo", src: require("./images/Jan2025/25-5.jpg") },
      { type: "photo", src: require("./images/Jan2025/25-6.jpg") },
      { type: "photo", src: require("./images/Jan2025/25-8.jpg") },
      { type: "photo", src: require("./images/Jan2025/25-7.jpg") },
    ],
    instaLink: "http://www.instagram.com/p/DFRJJgnOBec",
    fbLink: "http://www.facebook.com/share/p/15uh7LAsMP",
    youtubeLink: "http://youtube.com/post/Ugkxgq42Z52A3afMLeWlA0DYQQ8j_qDc5gb0",
    twitterLink: "http://x.com/atlpma/status/1883304672020328497",
    linkedinLink:
      "http://www.linkedin.com/posts/atlpma_apma-meditation-atlantatamilmandram-activity-7289070205132058624-Hpu8",
  },  
  {
    id: 8,
    date: "Jan 25, 2025",
    title: "Gentle resonance of singing bowls deepened the practice",
    host: "Garden of Gods",
    location: "Cumming, GA.",
    count: 10,
    description:
      "Saturday morning brought a profound experience of breath and sleep meditation amidst the serene beauty of Garden of the Gods. The gentle resonance of singing bowls deepened the practice, creating harmony and stillness. A truly rejuvenating journey into inner peace.",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/25-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/25-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/25-3.jpg") },
      
    ],
    instaLink: "http://www.instagram.com/reel/DFQOsRhOpYu",
    fbLink: "http://www.facebook.com/share/r/1FH6eozoo4",
    youtubeLink: "http://youtube.com/shorts/PNQlkXVW7lA",
    twitterLink: "http://x.com/atlpma/status/1883176229941154020",
    linkedinLink:
      "http://www.linkedin.com/posts/atlpma_breathmeditation-sleepmeditation-gardenofthegods-activity-7288942112815955968-rwpz",
  },
  {
    id: 7,
    date: "Jan 20, 2025",
    title: "Weekend Sound Bath Meditation",
    host: "Ashwini & Gajendra",
    location: "Cumming, GA.",
    count: 25,
    description:
      "Heartfelt gratitude to Master Ashwini & Gajendra for offering a sacred and energetic space for a beautiful group meditation. It was a truly uplifting and joyful experience in divine energy..",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/20-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/20-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/20-3.jpg") },
      { type: "photo", src: require("./images/Jan2025/20-4.jpg") },
      { type: "photo", src: require("./images/Jan2025/20-5.jpg") }
    ],
    instaLink: "https://www.instagram.com/p/DFF-BEuu5zG",
    fbLink: "https://www.facebook.com/share/p/19zqWYYjTH",
    youtubeLink: "http://youtube.com/post/UgkxM8D24NdrtvVDFOSaHkfTJ5n9PcazUGjl",
    twitterLink: "https://x.com/atlpma/status/1880788443581874432",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_groupmeditation-sacredspace-divineenergy-activity-7287498403998052352-fqW2",
  },
  {
    id: 6,
    date: "Jan 18, 2025",
    title: "Weekend Morning Meditation",
    host: "Mrudula & Sunil",
    location: "Cumming, GA.",
    count: 25,
    description:
      "Heartfelt gratitude to Master Mrudula & Sunil for offering a sacred and energetic space for a beautiful group meditation. It was a truly uplifting and joyful experience in divine energy.",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/18-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/18-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/18-3.jpg") },
      { type: "photo", src: require("./images/Jan2025/18-4.jpg") },
      { type: "photo", src: require("./images/Jan2025/18-5.jpg") }
    ],
    instaLink: "https://www.instagram.com/p/DFBAD0FuM35",
    fbLink: "https://www.facebook.com/share/p/18vzTEFs8j",
    youtubeLink: "http://youtube.com/post/Ugkx8zEquagwgtl9vAUXX5YLGt20r9iDXj47",
    twitterLink: "https://x.com/atlpma/status/1881033144855629939",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_groupmeditation-sacredspace-divineenergy-activity-7286798609415577601-6i-j",
  },
  {
    id: 5,
    date: "Jan 17, 2025",
    title: "Weekend Meditation Retreat",
    host: "Swetha & Srujan",
    location: "Cumming, GA.",
    count: 20,
    description:
      "Heartfelt gratitude to Master Swetha for offering a sacred and energetic space for a beautiful group meditation. It was a truly uplifting and joyful experience in divine energy. Would you like us to bring meditation to your space? Let us know!",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/17-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/17-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/17-3.jpg") },
    ],
    instaLink: "http://instagram.com/p/DE_RHkqO_TT",
    fbLink: "http://fb.com/share/p/19N68GWjpH",
    youtubeLink: "http://youtube.com/post/UgkxEfZ8HVddDhzo8KqF57zOIt0Gcp0pzYdE",
    twitterLink: "https://x.com/atlpma/status/1880788443581874432",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_groupmeditation-sacredspace-divineenergy-activity-7286553980376100864-YJNN",
  },
  {
    id: 5,
    date: "Jan 13, 2025",
    title: "Day 3 - Full Moon Meditation",
    host: "Sheetal & Chandra",
    location: "JohnsCreek, GA.",
    count: 30,
    description:
      "🌖We successfully completed our 3 Day Full Moon meditation session on the first full moon of the new year. 30 masters attended the session, Fire ceremony and circle of friendship, messages from Shaman masters, Intentional full moon affirmation meditation, including music meditation, a soothing shaman sound bath played by Sheetal, and a joyful gathering filled with vibrant energy.🌓",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/13-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/13-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/13-3.jpg") },
      { type: "photo", src: require("./images/Jan2025/13-4.jpg") },
      { type: "photo", src: require("./images/Jan2025/13-5.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEz-TuYxruF",
    fbLink: "https://www.facebook.com/share/r/18SBv4Wbwu",
    youtubeLink: "https://youtube.com/shorts/myXh--SZ0L8",
    twitterLink: "https://x.com/atlpma/status/1878120504445288939",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_fullmoonmeditation-shamanichealing-fireceremony-activity-7284965586235805696-2UI4",
  },
  {
    id: 4,
    date: "Jan 12, 2025",
    title: "Day 2 - Full Moon 24hrs Akhanda Dhyana (Meditation)",
    host: "Pushpa & Damodar",
    location: "Alpharetta, GA.",
    count: 50,
    description:
      "🌖We successfully completed a 24-hour meditation session on the first full moon of the new year, hosted by Master Pushpa.50 participants attended and featured deeply cherished moments, including music meditation, a soothing sound bath, and a joyful gathering filled with vibrant energy.🌓",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/12-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/12-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/12-3.jpg") },
      { type: "photo", src: require("./images/Jan2025/12-4.jpg") },
      { type: "photo", src: require("./images/Jan2025/12-5.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEwJjx8uES7",
    fbLink: "https://www.facebook.com/share/r/1BcaGG3gGg",
    youtubeLink: "https://youtube.com/shorts/8sNUhb40rNE",
    twitterLink: "https://x.com/atlpma/status/1878120504445288939",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_fullmoonmeditation-soundbath-musicmeditation-activity-7284427725774168064-rC3N",
  },
  {
    id: 3,
    date: "Jan 10, 2025",
    title: "Day 1 - Full Moon Overnight Meditation",
    host: "Sirisha & Narasimha",
    location: "Suwanee, GA.",
    count: 5,
    description:
      "The Himalayan Masters grace Atlanta for the first full moon of the year in the form of snow !Feeling immensely blessed to witness this divine moment. Deep gratitude to Nature for bringing such joy and fulfillment. Meditating together to honor and express our gratitude. 😇😇😇",
    mediaItems: [
      { type: "photo", src: require("./images/Jan2025/11-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/11-3.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEsTme-OOzF",
    fbLink: "https://www.facebook.com/share/r/14pYrwPsFU",
    youtubeLink: "https://youtube.com/shorts/BCbRnT0OB3g",
    twitterLink: "https://x.com/atlpma/status/1878120504445288939",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_himalayanmasters-fullmoonmeditation-gratitudetonature-activity-7283890045155950592-aPPA",
  },
  {
    id: 2,
    date: "Jan 04, 2025",
    title: "APMA First Tour - Temple of Universe - 2025",
    host: "APMA Team",
    location: "Gainsville, FL.",
    count: 16,
    description:
      "Meeting Michael A. Singer, the author of The Surrender Experiment, at the Temple of the Universe was a truly divine experience. 🧬Meditating alongside such a master was profoundly inspiring. 😇😇😇",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Jan2025/4-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/4-2.jpg") },
    ],
    instaLink: "https://www.instagram.com/p/DEfO7YmOFFx",
    fbLink: "https://www.facebook.com/share/p/1KFvtJbNWC",
    youtubeLink: "http://youtube.com/post/UgkxRNLYkIVryHkNciCIHmgtFSgCKMlGug_1",
    twitterLink: "https://x.com/atlpma",
    linkedinLink:
      "https://www.linkedin.com/feed/update/urn:li:activity:7282264325073899520",
  },
  {
    id: 1,
    date: "Jan 03, 2025",
    title: "Group Meditation",
    host: "Sahith & Satish Chandra",
    location: "Cumming, GA.",
    count: 30,
    description:
      "The group meditation brought a profound sense of peace, followed by an enriching session of experience sharing with the masters. Each participant felt a deep calm, fostering connection and inner tranquility.!",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Jan2025/3-1.jpg") },
      { type: "photo", src: require("./images/Jan2025/3-2.jpg") },
      { type: "photo", src: require("./images/Jan2025/3-3.jpg") },
      { type: "photo", src: require("./images/Jan2025/3-4.jpg") },
      { type: "photo", src: require("./images/Jan2025/3-5.jpg") },
    ],
    instaLink: "https://www.instagram.com/atlpma",
    fbLink: "https://www.facebook.com/share/p/155ynnA9B3",
    youtubeLink: "http://youtube.com/post/UgkxWUsN1h_NVmn8DOazjwZurFeC0t7RdK1h",
    twitterLink: "https://x.com/atlpma/status/1875365203828941202",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_groupmeditation-meditationjourney-innerpeace-activity-7281131004390424576-Tnis",
  },
];

const eventData = [
  // {
  //   id: 24,
  //   date: "Jan 03, 2025",
  //   title: "Group Meditation",
  //   host: "Sahith & Satish Chandra",
  //   location: "Cumming, GA.",
  //   count: 30,
  //   description:
  //     "The group meditation brought a profound sense of peace, followed by an enriching session of experience sharing with the masters. Each participant felt a deep calm, fostering connection and inner tranquility.!",
  //   mediaItems: [
  //     // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
  //     { type: "photo", src: require("./images/Jan2025/3-1.jpg") },
  //     { type: "photo", src: require("./images/Jan2025/3-2.jpg") },
  //     { type: "photo", src: require("./images/Jan2025/3-3.jpg") },
  //     { type: "photo", src: require("./images/Jan2025/3-4.jpg") },
  //     { type: "photo", src: require("./images/Jan2025/3-5.jpg") },
  //   ],
  //   instaLink: "https://www.instagram.com/atlpma",
  //   fbLink: "https://www.facebook.com/share/p/155ynnA9B3",
  //   youtubeLink: "http://youtube.com/post/UgkxWUsN1h_NVmn8DOazjwZurFeC0t7RdK1h",
  //   twitterLink: "https://x.com/atlpma/status/1875365203828941202",
  //   linkedinLink:
  //     "https://www.linkedin.com/posts/atlpma_groupmeditation-meditationjourney-innerpeace-activity-7281131004390424576-Tnis",
  // },
  {
    id: 23,
    date: "Dec 20 - 30, 2024",
    title: "11 Day Grand Celebrations of Dhyana Maha Yagam - 2024",
    host: "All Atlanta Masters",
    location: "Various Atlanta Locations.",
    count: 200,
    description:
      "Around 200 Masters made it to these events. 🎶 Daily Wisdom and Experience sharing by Our Masters and New Participants!🧘‍♂️ Every one enjoyed deep meditation and spiritual rejuvenation in a serene environment. Special activities for Kids.😇🙏🏻Jai Ho Patriji!",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec2024/PDMY2024.png") },
    ],
    instaLink: "https://www.instagram.com/atlpma",
    fbLink: "https://www.facebook.com/atlpma",
    youtubeLink: "https://youtube.com/@atlpma",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://www.linkedin.com/channel/atlpma",
  },
  {
    id: 22,
    date: "Dec 30, 2024",
    title: "Day-11 Dhyana Maha Yagam - 2024",
    host: "PSR Shekara Chinta & Devi",
    location: "JohnsCreek, GA.",
    count: 100,
    description:
      "The final day of the Dhyana Maha Yagam, hosted by Sekhara and Devi, was a resounding success with a full house of attendees. Over 100 participants, including many newcomers, experienced the profound energy and transformative power of meditation. The atmosphere was vibrant, filled with kindness and spirituality. A dedicated session for kids added an extra dimension of joy and connection, making the event truly unforgettable. 😇🙏🏻Jai Ho Patriji!",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec2024/30-1.jpg") },
      { type: "photo", src: require("./images/Dec2024/30-2.jpg") },
      { type: "photo", src: require("./images/Dec2024/30-3.jpg") },
      { type: "photo", src: require("./images/Dec2024/30-4.jpg") },
      { type: "photo", src: require("./images/Dec2024/30-5.jpg") },
      { type: "photo", src: require("./images/Dec2024/30-6.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEPzqJNuAsk",
    fbLink: "https://www.facebook.com/share/r/15kVAhWZXM",
    youtubeLink: "https://youtube.com/shorts/sbES5AvNnCI",
    twitterLink: "https://x.com/atlpma/status/1874109090726908379",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhyanamahayagam-meditationforall-spiritualvibes-activity-7279874947122900992-Eoyj",
  },
  {
    id: 21,
    date: "Dec 29, 2024",
    title: "Day-10 Dhyana Maha Yagam - 2024",
    host: "Team APMA",
    location: "George Pierce Park Community center, Suwanee, GA.",
    count: 100,
    description:
      "The grand celebration of PDMY 2024 unfolded with divine energy, as nearly 80 masters united in a powerful group meditation. Guided by the dedicated APMA team, the event radiated harmony, enriched by exquisite cultural dance performances and soulful, nourishing food. A truly uplifting and unforgettable spiritual gathering!",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec2024/29-1.jpg") },
      { type: "photo", src: require("./images/Dec2024/29-2.jpg") },
      { type: "photo", src: require("./images/Dec2024/29-3.jpg") },
      { type: "photo", src: require("./images/Dec2024/29-4.jpg") },
      { type: "photo", src: require("./images/Dec2024/29-5.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEMJsHLuhcJ",
    fbLink: "https://www.facebook.com/share/r/1AYDN8M2jM",
    youtubeLink: "https://youtube.com/shorts/IORInXg7Os4",
    twitterLink: "https://x.com/atlpma/status/1873595105195786251",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhanamahayagam-meditationevent-communitygathering-activity-7279361095713103872-Z7RX",
  },
  {
    id: 21,
    date: "Dec 28, 2024",
    title: "Day-9 Dhyana Maha Yagam - 2024",
    host: "Anila Sreekanth",
    location: "JohnsCreek, GA.",
    count: 30,
    description:
      "We are deeply grateful for the wonderful celebration of Dhyana Maha Yagam! Thank you, Anila & Sreekanth, for hosting an unforgettable evening filled with profound meditation, heartwarming connections, and mouthwatering food. It was truly a night to cherish with our incredible community.",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec2024/28-1.jpg") },
      { type: "photo", src: require("./images/Dec2024/28-2.jpg") },
      { type: "photo", src: require("./images/Dec2024/28-3.jpg") },
      { type: "photo", src: require("./images/Dec2024/28-4.jpg") },
      { type: "photo", src: require("./images/Dec2024/28-5.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEJVnNMuH9K",
    fbLink: "https://www.facebook.com/share/r/12GPj78wPC4",
    youtubeLink: "https://youtube.com/shorts/vmYlUregSjg",
    twitterLink: "https://x.com/atlpma/status/1873198193368052001",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhyanamahayagam-meditationjourney-gratefulhearts-activity-7278964034807197696-WR3x",
  },
  {
    id: 20,
    date: "Dec 27, 2024",
    title: "Day-8 Dhyana Maha Yagam - 2024",
    host: "Swetha",
    location: "Cumming, GA.",
    count: 30,
    description:
      "We had a truly wonderful experience celebrating Dhyana Maha Yagam! A big thank you to Swetha for hosting an unforgettable evening filled with profound meditation, delightful food, and meaningful connections.",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec2024/27-1.jpg") },
      { type: "photo", src: require("./images/Dec2024/27-2.jpg") },
      { type: "photo", src: require("./images/Dec2024/27-3.jpg") },
      { type: "photo", src: require("./images/Dec2024/27-4.jpg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEH1QJPxrH3",
    fbLink: "https://www.facebook.com/share/r/1BDXmpxWJG",
    youtubeLink: "https://youtube.com/shorts/5IuOp558fbg",
    twitterLink: "https://x.com/atlpma/status/1872986162241089980",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhyanamahayagam-meditationgathering-gratitude-activity-7278752046348939264-ZjZj",
  },
  {
    id: 19,
    date: "Dec 26, 2024",
    title: "Day-7 Dhyana Maha Yagam - 2024",
    location: "Suwanee, GA.",
    host: "Sirisha & Narasimha",
    description:
      "We had an amazing time celebrating Dhyana Maha Yagam. A heartfelt thank you to Sirisha and Narasimha for hosting such a memorable evening filled with soulful meditation, delicious food, and beautiful connections.",
    count: 40,
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec26Day7_1.jpeg") },
      { type: "photo", src: require("./images/Dec26Day7_2.jpeg") },
      { type: "photo", src: require("./images/Dec26Day7_3.jpeg") },
      { type: "photo", src: require("./images/Dec26Day7_4.jpeg") },
      { type: "photo", src: require("./images/Dec26Day7_5.jpeg") },
      { type: "photo", src: require("./images/Dec26Day7_6.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEEXxhmOk18",
    fbLink: "https://www.facebook.com/share/r/1c99bYKPHc",
    youtubeLink: "https://youtube.com/shorts/cBk776ZKpTE",
    twitterLink: "https://x.com/atlpma/status/1872282175720345904",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhyanamahayagam-meditationevent-gratitude-activity-7278266094203613184-q_t8",
  },
  {
    id: 18,
    date: "Dec 25, 2024",
    title: "Day-6 Dhyana Maha Yagam - 2024",
    location: "Cumming, GA.",
    host: "Sreenivas & Anuradha",
    count: 30,
    description:
      "We had an incredible Christmas party celebrating the Dhyana Maha Yagam event! Heartfelt thanks to Sreeni and Anu for hosting such a beautiful evening filled with soulful meditation, delicious food",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec25Day6_1.jpeg") },
      { type: "photo", src: require("./images/Dec25Day6_2.jpeg") },
      { type: "photo", src: require("./images/Dec25Day6_3.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DEC02SWOpG8",
    fbLink: "https://www.facebook.com/share/r/1GMbdMMWpb",
    youtubeLink: "https://youtube.com/shorts/5vL-JdXzrcg",
    twitterLink: "https://x.com/atlpma/status/1871788658921271646",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhyanamahayagam-meditationevent-soulfulevening-activity-7278048044636352521-5cny",
  },
  {
    id: 17,
    date: "Dec 24, 2024",
    title: "Day-5 Dhyana Maha Yagam - 2024",
    location: "Cumming, GA.",
    host: "Ashwini & Gajendra",
    count: 34,
    description:
      "We had an incredible Christmas Eve celebrating the Dhyana Maha Yagam event! Heartfelt thanks to Ashwini and Gajendra for hosting such a beautiful evening filled with soulful meditation, delicious food, cake, and endless desserts. We also enjoyed playing games and dancing together. Truly a night to cherish!",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec24Day5.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_1.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_2.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_3.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_4.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_5.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_6.jpeg") },
      { type: "photo", src: require("./images/Dec24Day5_7.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DD_UUG5Ox--",
    fbLink: "https://www.facebook.com/share/r/15hMj8BKR1",
    youtubeLink: "https://youtube.com/shorts/TGDuL8tjzL8",
    twitterLink: "https://x.com/atlpma/status/1871788658921271646",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_christmasevecelebration-dhyanamahayagam-meditationevent-activity-7277554612318912512-9XtF",
  },
  {
    id: 16,
    date: "Dec 23, 2024",
    title: "Day-4 Dhyana Maha Yagam - 2024",
    host: "Vani Suruvu",
    count: 50,
    description:
      "Honoring the spirit of Dhyana Maha Yagam with a powerful group meditation session! Deep gratitude to Master Vani Suruvu for their heartfelt hosting of this transformative event. 🌟",
    location: "Johns Creek, GA.",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec23.jpeg") },
      { type: "photo", src: require("./images/Dec23Day4_1.jpeg") },
      { type: "photo", src: require("./images/Dec23Day4_2.jpeg") },
      { type: "photo", src: require("./images/Dec23Day4_3.jpeg") },
      { type: "photo", src: require("./images/Dec23Day4_4.jpeg") },
      { type: "photo", src: require("./images/Dec23Day4_5.jpeg") },
      { type: "photo", src: require("./images/Dec23Day4_6.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DD9vRhmOeNY",
    fbLink: "https://www.facebook.com/share/r/1XX3ivA4CC",
    youtubeLink: "https://youtube.com/shorts/80y1LvsPft4",
    twitterLink: "https://x.com/atlpma/status/1871055256051953950",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_wintersolstice-dhyanamahayagam-groupmeditation-activity-7277332662254497792-UEK8",
  },
  {
    id: 15,
    date: "Dec 22, 2024",
    title: "Day-3 Dhyana Maha Yagam - 2024",
    host: "Praveen & Rekha",
    description:
      "Honoring the spirit of Dhyana Maha Yagam with a powerful group meditation session! Deep gratitude to Masters Praveen & Rekha for their heartfelt hosting of this transformative event. 🌟",
    location: "Beckham St, Cumming GA 30328",
    count: 30,
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec22.jpeg") },
      { type: "photo", src: require("./images/Dec22Day3.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DD6G2-_u6Pp",
    fbLink: "https://www.facebook.com/share/r/1Aer28GAoQ",
    youtubeLink: "https://youtube.com/shorts/SDitASV-Gfo",
    twitterLink: "https://x.com/atlpma/status/1871055256051953950",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_wintersolstice-dhyanamahayagam-groupmeditation-activity-7276822489740058624-nKy6",
  },
  {
    id: 14,
    date: "Dec 21, 2024",
    title: "Day-2 Dhyana Maha Yagam - 2024",
    host: "Divya Makam",
    description:
      "🔮🧬Celebrating the powerful Winter Solstice energy with Dhyana Maha Yagam through group meditation! 🪭A joyful gathering filled with laughter, food, and togetherness. 🪭Grateful to Master Divya for beautifully hosting this event.",
    count: 20,
    location: "Sandy Springs, GA 30328",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec21Day2.jpeg") },
      { type: "photo", src: require("./images/Dec21Day2_1.jpeg") },
      { type: "photo", src: require("./images/Dec21Day2_2.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DD3ZjkEO9rt",
    fbLink: "https://www.facebook.com/share/r/15n5rqMxYQ",
    youtubeLink: "https://www.youtube.com/shorts/8r9wAbjdqCg",
    twitterLink: "https://x.com/atlpma/status/1870675567168893281",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_wintersolstice-dhyanamahayagam-groupmeditation-activity-7276440778095124480-uaSY",
  },
  {
    id: 13,
    date: "Dec 21, 2024",
    title: "World Meditation day - 2024",
    location: "Fowler Park, Cumming, GA 30040",
    description:
      "On December 21st, the Atlanta Pyramid Meditation Academy gathered at Fowler Park, Cumming, GA, to celebrate positivity, mindfulness, and spirituality. 🙏✨ Meditation masters shared their inspiring journeys and how meditation transformed their lives, sparking enriching discussions on spirituality and self-discovery.",
    host: "APMA Team",
    count: 15,
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec21.jpeg") },
      { type: "photo", src: require("./images/Dec21_1.jpeg") },
      { type: "photo", src: require("./images/Dec21_2.jpeg") },
      // { type: "photo", src: require("./images/Dec14_4.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DD2uFJhsvNV",
    fbLink: "https://www.facebook.com/share/r/1E3syqo6PY",
    youtubeLink: "https://youtube.com/shorts/kOpnEqnKyAM",
    twitterLink: "https://x.com/atlpma/status/1870578435845919086",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_worldmeditationday-atlantapyramidmeditationacademy-activity-7276343691282186240-t5MX",
  },
  {
    id: 12,
    date: "Dec 20, 2024",
    title: "Day-1 Dhyana Maha Yagam - 2024",
    location: "Warbler Way, Cumming, GA 30041",
    host: "Sahithi & Satish",
    count: 17,
    description:
      "Kickstarting the group meditation for the Dhyana Maha Yagam . Immense gratitude to Master Sahiti and Sathish for hosting this vibrant and energetic gathering!",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec20.jpeg") },
      { type: "photo", src: require("./images/Dec20_2.jpeg") },
      { type: "photo", src: require("./images/Dec20_4.jpeg") },
      { type: "photo", src: require("./images/Dec20_1.jpeg") },
      // { type: "photo", src: require("./images/Dec14_4.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DD08vGJOzVE",
    fbLink: "https://www.facebook.com/share/r/1CettjVL5G",
    youtubeLink: "https://youtube.com/shorts/DgYslkhQQ3U",
    twitterLink: "https://x.com/atlpma/status/1870329726503420247",
    linkedinLink:
      "https://www.linkedin.com/posts/atlpma_dhyanamahayagam-groupmeditation-spiritualgathering-activity-7276095818875420672-ZRjf",
  },
  {
    id: 11,
    date: "Dec 14, 2024",
    title: "24 Hours Akanda Dhyanam",
    location: "Lacebark Pine Ct, Cumming, GA 30040",
    host: "Arun Alladurgam",
    description:
      "💫Completed a transformative 24-hour continuous meditation session! 💫😇🙏🏻Grateful to Arun for providing the sacred space for this journey. Nearly 20 participants joined, experiencing profound energies and growth.",
    count: 50,
    mediaItems: [
      { type: "flyer", src: require("./images/Dec1424.jpeg") },
      { type: "photo", src: require("./images/Dec14_1.jpeg") },
      { type: "photo", src: require("./images/Dec14_2.jpeg") },
      { type: "photo", src: require("./images/Dec14_3.jpeg") },
      // { type: "photo", src: require("./images/Dec14_4.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DDlXeqiuXuM",
    fbLink: "https://www.facebook.com/share/r/15Me4c1ALd",
    youtubeLink: "https://youtube.com/shorts/oRPoOWgv-Tk",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 10,
    date: "Dec 08, 2024",
    title: "Thanksgiving Gratitude",
    location: "Coal Mountain Park, Cumming, GA 30028",
    count: 70,
    description:
      "Today, we embraced gratitude and mindfulness during our Thanksgiving Meditation in Atlanta. Sharing positive energy, connecting with nature, and cherishing togetherness—what a beautiful way to celebrate the season of thanks! 🥳😇🤝🙏🏻",
    host: "APMA Team",
    mediaItems: [
      // { type: "flyer", src: require("./images/Dec0824.png") },
      { type: "photo", src: require("./images/Dec08_1.jpeg") },
      { type: "photo", src: require("./images/Dec8_2.jpeg") },
      { type: "photo", src: require("./images/Dec8_3.jpeg") },
      { type: "photo", src: require("./images/Dec8_4.jpeg") },
      { type: "photo", src: require("./images/Dec8_5.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DDU51u1RmqV",
    fbLink: "https://www.facebook.com/share/r/15b86ux1ks",
    youtubeLink: "https://youtube.com/shorts/9dV93aIOD2M",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 9,
    date: "Dec 01, 2024",
    title: "Kids Meditation",
    location: "APMA Office, Alpharetta, GA - 30004",
    host: "Pushpa Pocham",
    count: 15,
    description:
      "The APMA successfully completed a 4-week Fall Meditation program for kids and teens, introducing them to transformative concepts like Meditation, Friendship with Self, Gratitude, Secret Power, Thought Power, and Yoga Asanas. As part of the program, they participated in a Thought Power Workshop, learning how to break patterns of negative thoughts and replace them with positive, empowering ones using meditation techniques.",
    mediaItems: [
      // { type: "flyer", src: require("./images/Kids1201.jpeg") },
      { type: "photo", src: require("./images/Kids1201pic.jpeg") },
    ],
    instaLink: "https://www.instagram.com/p/DDDoM97uko2",
    fbLink: "https://www.facebook.com/share/p/19xVZgEu4y",
    youtubeLink: "http://youtube.com/post/UgkxhwnnoOk3Fbq4WyRFRqPuo0cTx-ru4zVV",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 8,
    date: "Dec 01, 2024",
    title: "Sunday Evening Meditation",
    location: "APMA Office, Alpharetta, GA - 30004",
    mediaItems: [
      // { type: "flyer", src: require("./images/AtlPMA1201.jpeg") },
      { type: "photo", src: require("./images/AtlPMA1201pic.jpeg") },
    ],
    instaLink: "https://www.instagram.com/p/DDC3vIpv2y2",
    fbLink: "https://fb.watch/wcJZMCqa2f",
    youtubeLink: "https://youtube.com/shorts/t968zaouCVw",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 7,
    date: "Nov 24, 2024",
    title: "Kids Meditation",
    location: "APMA Office, Alpharetta, GA - 30004",
    mediaItems: [
      // { type: "flyer", src: require("./images/AtlKids1124.jpeg") },
      { type: "photo", src: require("./images/AtlKids1124_2.jpeg") },
    ],
    instaLink: "https://www.instagram.com/share/BAOZNJrh7_",
    fbLink: "https://www.facebook.com/share/p/127LetsBVsa",
    youtubeLink: "http://youtube.com/post/Ugkxpnt8bPz1SfSDXkFadAU5Vp3_e9nsp7PV",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 6,
    date: "Nov 24, 2024",
    title: "Neighborhood 11:11 Meditation",
    location: "cumming, GA - 30041",
    mediaItems: [
      // { type: "flyer", src: require("./images/APMA1124Flyer.jpeg") },
      { type: "photo", src: require("./images/APMA1124.jpeg") },
      { type: "photo", src: require("./images/APMA1124_2_1.jpeg") },
      { type: "photo", src: require("./images/APMA1124_3_1.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DCx5kOMO4M9",
    fbLink: "https://www.facebook.com/share/r/15a5cQCWbR",
    youtubeLink: "https://youtube.com/shorts/1obh6jtbhB8",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },

  {
    id: 5,
    date: "Nov 23, 2024",
    title: "Meditation at APMA center",
    location: "Alpharetta GA 30004",
    mediaItems: [
      // { type: "flyer", src: require("./images/APMA1124_flyer.jpeg") },
      { type: "photo", src: require("./images/APMA1123.jpeg") },
      { type: "photo", src: require("./images/APMA1124_2.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DCuUactR87L",
    fbLink: "https://www.facebook.com/share/r/19mkNKyrFR",
    youtubeLink: "https://youtube.com/shorts/KJn2jJqHEDo",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 4,
    date: "Nov 17, 2024",
    title:
      "Meditation at Nrupathunga Kannada Koota (NKK) Rajyotsava and Deepavali 2024",
    location: "Drew Road Cumming GA 30040",
    mediaItems: [
      // { type: "flyer", src: require("./images/NKKStall1.jpeg") },
      { type: "photo", src: require("./images/NKKStall2.jpeg") },
      { type: "photo", src: require("./images/NKKStall3.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DChF0HFpdsc",
    fbLink: "https://www.facebook.com/share/r/1DTMKQydCN",
    youtubeLink: "https://youtube.com/shorts/rYwshaS42qc",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 4,
    date: "Nov 17, 2024",
    title: "Kids Meditation",
    location: "Alpharetta GA 30004",
    mediaItems: [
      // { type: "flyer", src: require("./images/kid2.jpeg") },
      { type: "photo", src: require("./images/KidsPhoto1.jpeg") },
      { type: "photo", src: require("./images/KidsPhoto2.jpeg") },
    ],
    instaLink: "https://instagram.com/atlpma",
    fbLink: "https://facebook.com/atlpma",
    youtubeLink: "https://www.youtube.com/pmcusa",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 3,
    date: "Nov 15, 2024",
    title: "Full Moon Meditation",
    location: "Lacebark, Pine Ct, Cumming, GA",
    mediaItems: [
      // { type: "flyer", src: require("./images/FullMoon24hrMeditation.jpeg") },
      { type: "photo", src: require("./images/24hrMeditation1.jpeg") },
      { type: "photo", src: require("./images/24hrMeditation2.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DCdU3gktJ65",
    fbLink: "https://www.facebook.com/share/r/1ApaAsP9oS",
    youtubeLink: "https://youtube.com/shorts/DP1Gs5TFyX4",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 2,
    date: "Nov 11, 2024",
    title: "Patriji's 77th Birthday Celebrations",
    location: "Lotus Meditation Center, Atlanta, GA",
    mediaItems: [
      // { type: "flyer", src: require("./images/PatrijiBdayFlyer.jpeg") },
      { type: "photo", src: require("./images/PatrijiBdayCel.jpeg") },
      { type: "photo", src: require("./images/patrijiBday2.jpeg") },
    ],

    instaLink: " https://www.instagram.com/reel/DCR1S5-x69p",
    fbLink: "https://www.facebook.com/share/r/15CsuTKZur",
    youtubeLink: "https://youtube.com/shorts/-5bNE82u5Nk",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
  {
    id: 1,
    date: "Nov 10, 2024",
    title: "Patriji's 77th Birthday Celebrations",
    location: "APMA Meditation Center, Atlanta, GA",
    mediaItems: [
      // { type: "flyer", src: require("./images/PatrijiBdayFlyer1.jpeg") },
      { type: "photo", src: require("./images/PatrijiBdayApma2.jpeg") },
      { type: "photo", src: require("./images/PatrijiBdayApma1.jpeg") },
    ],
    instaLink: "https://www.instagram.com/reel/DCNypZuP1TX",
    fbLink: "https://www.facebook.com/share/r/1BADYgctJK",
    youtubeLink: "https://youtube.com/shorts/by0Knx_52iQ",
    twitterLink: "https://x.com/atlpma",
    linkedinLink: "https://linkedin.com/company/atlpma",
  },
];

const LocalEventDetails = [
  {
    id: 6,
    title: "Patri Sir Birthday Celebrations 2024 at Lotus Meditation Center",
    date: "Nov 11th, 2024",
    img: require("./images/PatrijiBdayCel.jpeg"),
    location: "Lotus Meditation Center, Atlanta, GA",
  },
  {
    id: 5,
    title: "Patri Sir Birthday Celebrations 2024 at APMA Center",
    date: "Nov 10th, 2024",
    img: require("./images/PatrijiBday2024.jpeg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 4,
    title: "Kids Meditation at APMA Center",
    date: "Nov 10th, 2024",
    img: require("./images/KidsMeditation.jpeg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 3,
    title: "Patri Sir Birthday Celebrations 2023 at APMA Center",
    date: "Nov 11th, 2023",
    img: require("./images/PatriSirBday.jpg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 2,
    title: "Seth Wisdom Workshop by Bala Krishna Sir",
    date: "Oct 15th, 2023",
    img: require("./images/SethWS.jpg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 1,
    title: "Crystal Kids Meditation - Certificate Program",
    date: "Oct 29th, 2023",
    img: require("./images/Crystal_KIDS_Meditation_crtf.jpg"),
    location: "APMA Center, Atlanta, GA",
  },
];

const USA_EventDetails = [
  {
    id: 1,
    title: "Mega Meditation Summit (Dhyanma Maha Chakram) @ Dallas",
    date: "Oct 8th, 2023",
    img: require("./images/MMS_Event.jpeg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 2,
    title: "Pyramid Bhumi Pooja @ Raliegh",
    date: "",
    img: require("./images/RaleighFirstPyramidLayingStone.jpg"),
    location: "Raliegh, NC",
  },
  {
    id: 3,
    title: "Patriji Amruthosthav @ Maryland",
    date: "Nov 11th, 2023",
    img: require("./images/DMMS_Dallas.jpg"),
    location: "Maryland, MD",
  },
];

export { LocalEventDetails, USA_EventDetails, eventData, eventData2025 };
