const OngoingProgramDetails = [
  // {
  //   id: 1,
  //   title: "40 days Meditation Challenge",
  //   date: "EVERY MORNING",
  //   time: "5.30 AM EST | 4.30 AM CST",
  //   img: require("./images/Mrng40daysMeditation.jpeg"),
  //   zoom_link:
  //     "https://northeastern.zoom.us/j/94268782381?pwd=NcJgrz2yiUNucTrxWXDADleOLQ9wlc.1",
  //   zoom_meeting_id: "852 6795 5865",
  //   zoom_passcode: "1111",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },
];

const UpComingOnlinePrograms = [
  // {
  //   id: 1,
  //   title: "Kids & Teens Meditation Workshop",
  //   date: "Nov-10th",
  //   time: "2 pm - 3 pm EST",
  //   img: require("./images/AtlKids.jpeg"),
  //   zoom_link: "",
  //   zoom_meeting_id: "",
  //   zoom_passcode: "",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },
];

const RecentProgramDetails = [
  {
    id: 1,
    title: "Heal Your Life with Mirror Work workshop",
    date: "EVERY MORNING",
    time: "4.30 AM CST | 5.30 AM EST",
    img: require("./images/MirrorWS.jpeg"),
    zoom_link:
      "https://us02web.zoom.us/j/86865160629?pwd=uaZsSRppS410nTdiXNk91oOeqKZ2Dv.1",
    zoom_meeting_id: "868 6516 0629",
    zoom_passcode: "9999",
    youtube_link: "https://www.youtube.com/live/NhGB2aoVeu8",
  },
  {
    id: 8,
    title: "PSSM MEDITATION SUMMIT",
    date: "June 22nd",
    time: "9AM to 4PM EST",
    img: require("./images/Virgina_DMC.jpeg"),
    venu_location:
      "Eagle Ridge Middle School, 42901 Waxpool Rd, Ashburn, VA, 20148",
    registration_link: "http://tiny.cc/9wqxxz",
    youtube_link: "https://www.youtube.com/live/1vkvQ65bnic",
  },
  {
    id: 7,
    title: "PSSM MEDITATION SUMMIT",
    date: "May 25th",
    time: "9AM to 4PM EST",
    img: require("./images/PSSM_MINI_TEXAS_2.jpeg"),
    venu_location:
      "Shridi Sai Community Hall, 2509 W New Hope Dr, Cedar Park, TX 78613",
    registration_link: "https://guestlist.co/u/pssmaustin",
    youtube_link: "https://www.youtube.com/@PMCUSA",
  },
  {
    id: 6,
    title: "PSSM MEDITATION SUMMIT",
    date: "April 27th",
    time: "10 AM to 4PM EST",
    img: require("./images/MINI_DMC_OHIO_2.jpeg"),
    venu_location: "5071 , Wallings Rd, North Royalton, OH 44133",
    registration_link: "https://tinyurl.com/PSSM-Meditation-cle-OH",
    youtube_link: "https://www.youtube.com/watch?v=k8omuN1p-Pc",
  },
  {
    id: 5,
    title: "PSSM MEDITATION SUMMIT",
    date: "March 23rd",
    time: "9AM to 4PM EST",
    img: require("./images/PSSM_MINI_GA2.jpeg"),
    venu_location: "Banjara Banquets, 1656 Buford Hwy, Cumming, GA 30041",
    registration_link: "http://tinyurl.com/pssm-atl",
    youtube_link: "https://www.youtube.com/watch?v=99BMHJOCbCY",
  },
  {
    id: 4,
    title: "PSSM MEDITATION SUMMIT",
    date: " February 24th",
    time: "9AM to 4PM EST",
    img: require("./images/NJ_MMST.jpeg"),
    venu_location: "Ved Mandir, 1 Ved Mandir Dr, Milltown, NJ 08850",
    registration_link:
      "https://docs.google.com/forms/d/e/1FAIpQLScjRc7GlGICYRzGbxRBwZo3QXKdcpXOvpWK6RiELMh6UxFdgA/viewform",
    youtube_link: "https://www.youtube.com/watch?v=A6AruSLMYT4",
  },
  {
    id: 3,
    title: "PSSM MEDITATION SUMMIT",
    date: "27th January 2024 - Saturday",
    time: "10 AM to 4 PM CST",
    img: require("./images/Monthly_MMS_Jan.jpg"),
    // venu_location:
    //   "Nolensville First United Methodist Church, 7316 Nolensville Rd, Nolensville TN 37135",
    // registration_link:
    //   "https://docs.google.com/forms/d/e/1FAIpQLSdrSA5uFz5qQ6bBwT-_eAyIBrL2O7SZUTX6-xZAhM4l7RXzGg/viewform",
    youtube_link: "https://www.youtube.com/watch?v=XyKvcqvVlCM",
  },
  {
    id: 2,
    title: "Harmony Within - Workshop in Atlanta",
    date: "26th January 2024 - Friday",
    time: "6.30 PM to 8 PM EST",
    img: require("./images/SPT_Chandra_Sir.jpeg"),
    // venu_location:
    //   "Nine East Building (Behind Kroger) 1740 Grassland Parkway, Block 400 Alpharetta, Georgia 30004",
    // registration_link: "https://forms.gle/D7NhKtRrjX8eKxfG6",
    youtube_link: "https://www.youtube.com/watch?v=kUTAzZCdFH0",
  },
  {
    id: 1,
    title: "CHANGE WHAT YOU EAT CHANGE THE WORLD",
    date: "NOV 19th 2023 ",
    time: "8.30 PM to 10 PM EST",
    img: require("./images/gaurav.jpeg"),
    // zoom_link:
    //   "https://us02web.zoom.us/j/83299568390?pwd=SUJOZWsyUGE3ei9naEUvMTJUdHEwdz09",
    // zoom_meeting_id: "832 9956 8390",
    // zoom_passcode: "801942",
    youtube_link: "https://www.youtube.com/watch?v=8QSMI2Oy9Q4&t=3s",
  },
];

const InPersonProgramDetails = [
  {
    id: 1,
    title: "Sun Transition Group Meditation",
    date: "Jan-26th",
    time: "11:00 PM - 4:00 PM EST",
    host: "APMA Team",
    img: require("./images/Jan2025/Jan26.png"),
    venu_location: "Dream House Studios, 56 Perimeter Center E #3D, Dunwoody, GA 30346",
    registration_link: "https://bit.ly/sun-2025",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
];


export {
  RecentProgramDetails,
  OngoingProgramDetails,
  InPersonProgramDetails,
  UpComingOnlinePrograms,
};
