import React from "react";
// import { Routes, Route } from "react-router-dom";
import { Routes, Route, HashRouter as Router } from "react-router-dom";

import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import DonationPage from "./pages/DonationPage.jsx";
import StayTune from "./components/StayTune.jsx";
import History from "./pages/History.jsx";
import Event from "./pages/Events.jsx";
import Event2025 from "./pages/Events2025.jsx";
import OnlineProgramPage from "./pages/OnlineProgramPage.jsx";
import Contact from "./pages/Contact.jsx";
import Testimonials from "./pages/Testimonials.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <div>
      {/* <Router basename={"abc"}></Router> */}
      <Navbar />
      <div>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/donation" element={<DonationPage />} />
          <Route path="/experiance" element={<StayTune />} />
          <Route path="/events/2024" element={<Event />} />
          <Route path="/events" element={<Event2025 />} />
          <Route path="/Programs" element={<OnlineProgramPage />} />
          <Route path="/history" element={<History />} />
          <Route path="/books" element={<StayTune />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/local-masters" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
