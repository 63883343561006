import React from "react";
import zelleQR from "../images/ZelleQR.png";
import zeffyQR from "../images/icons/zeffy.png";

function Footer() {
  return (
    <footer className="footer">
      {/* Donation Section */}
      <p>
        Your contributions help us continue offering free events and programs.
        If you’d like to support our mission, feel free to donate via{" "}
        <strong>Zelle</strong> or <strong>Paypal</strong>:
      </p>

      <div className="donation-section">
        <div className="donation-email">
          <p>
            <strong>Atlanta Pyramid Meditation Academy</strong>
            <br />
            Email: <strong>donate_apma@pssmusa.org</strong>
          </p>
        </div>
        <div className="donation-qr">
          <div>
            <a
              href="https://www.paypal.com/donate/?hosted_button_id=RWJWHHJZPP3YA"
              target="_blank"
              rel="noopener noreferrer"
              className="donation-link"
            >
                
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Paypal_2014_logo.png"
                alt="PayPal"
                className="icon paypal-icon"
              />
            </a>
          </div>
          <div>
          <a
              href="https://www.zeffy.com/embed/donation-form/donate-to-make-a-difference-4600?modal=true"
              target="_blank"
              rel="noopener noreferrer"
              className="donation-link"
            >
            <img src={zeffyQR} alt="Zeffy QR Code" className="zeffy-code" /> 
            </a>
          </div>
          <div>
            <img src={zelleQR} alt="Zelle QR Code" className="qr-code" />
          </div>
        </div>
      </div>

      <p className="thank-you">
        Thank you for being part of this incredible journey. Let’s make it even
        more special together!
      </p>

      {/* Social Media Links */}
      <div className="social-media">
        <a
          href="https://instagram.com/atlpma"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Instagram_font_awesome.svg/512px-Instagram_font_awesome.svg.png"
            alt="Instagram"
            className="social-icon"
          />
        </a>
        <a
          href="https://facebook.com/atlpma"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f1/Ec-facebook.png"
            alt="Facebook"
            className="social-icon"
          />
        </a>
        <a
          href="https://x.com/atlpma"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/X_logo_2023.svg/300px-X_logo_2023.svg.png"
            alt="Twitter"
            className="social-icon"
          />
        </a>
        <a
          href="https://linkedin.com/company/atlpma"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Font_Awesome_5_brands_linkedin.svg/512px-Font_Awesome_5_brands_linkedin.svg.png"
            alt="LinkedIn"
            className="social-icon"
          />
        </a>
        <a
          href="https://www.threads.net/@atlpma"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/d/db/Threads_%28app%29.png"
            alt="Threads"
            className="social-icon"
          />
        </a>
        <a
          href="https://wa.me/message/2JEYRSBFPLMFN1"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Bootstrap_whatsapp.svg/480px-Bootstrap_whatsapp.svg.png"
            alt="WhatsApp"
            className="social-icon"
          />
        </a>
        <a
          href="https://www.youtube.com/@atlpma"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/YouTube_social_dark_circle_%282017%29.svg/256px-YouTube_social_dark_circle_%282017%29.svg.png"
            alt="YouTube"
            className="social-icon"
          />
        </a>
      </div>

      <p>
        <a
          href="https://forms.gle/pegJAt66StFS9Geo6"
          target="_blank"
          rel="noopener noreferrer"
          className="event-link"
        >
          Subscribe to our weekly newsletter here
        </a>
      </p>
    </footer>
  );
}

export default Footer;
