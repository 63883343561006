const TestimonialsDetails = [
  {
    id: 3,
    name: "Vani Prathap",
    title:
      "Transformative Journeys: Life-Changing Experiences with Meditation",
    text: "Meditation has been a life's game changer for me.\
     Its the prescription doctors gave me for my health condition.\
     Every time I do meditation, there have been many many benefits.\
     It helps in getting rid of any ailments -\
     from stress, headaches, pains, etc to unleashing my potential to next heights with each meditation.\
     Other benefits include getting good focus to complete a task with ease in less time,\
     staying calm in turbulence that in turn helped understand the situation better.\
     Long meditations have especially helped me to beat myself beyond my own expectations,\
     helping to complete more heavy tasks physically or mentally like winning hackathons.\
     The energy gained in meditations have been phenomenal.\
     Its like dancing to the tune of your own and that of the nature around.\
     Unknown things are revealed in great grandiose silent revelations.\
     Those signals from the body itself help in emphasizing the same\
Thank you. \
I felt like sharing my thoughts, and I did it.",
    image: require("./ContactPhotos/VaniSuruvu.jpg"),
  },
  {
    id: 1,
    name: "Ash Gajen",
    title:
      "My Transformative Journey to Self-Confidence and Inner Peace Through Meditation",
    text: "Hi, \
    I would like to share my healing ( meditation journey with u guys ). \
I started meditation 4.5 months before. \
What has improved in me. \
Self-confidence, I can sit still thinking about nothing. \
I can have a vision of what I need and what is important in my life rather than thinking about what has happened in the past or \
what is going to happen in the future. \
Before starting meditation myself I always wanted to go out and always I use to feel restless thinking oh god what has happened to me in past and \
what may happen in future which was unnecessary thought which was not helping me in anything and ruining my present. \
The day I started meditation I started getting myself calm down and was able to live in present as its a technique, \
which is concentrate on ur breathing while u start excelling it it will make u stay with present. \
Thank you. \
I felt like sharing my thoughts, and I did it.",
    image: require("./ContactPhotos/ashwini.jpg"),
  },
  {
    id: 2,
    name: "Sreeni N",
    title: "Transformative Meditation Experience Under the Hanging Pyramid",
    text: "Hi APMA Team, I have been meditating for the past 6+ months and \n\
     here are my Overall experiences and profound benefits achieved so far 😊, \n\
     thought of sharing my dear Masters.\n\
1) First thing I noticed Sleep Quality got Improved tremendously,very good Core/Deep sleep time.\n\
2) More Energized body to become more Active to handle daily activities.\n\
3) Stress level reduced, which in-turn reduced the cortisol and blood sugar levels.\n\
4) Eye Site got improved tremendously, which is a surprise to me.\n\
5) Body Weakness got completely reduced which is my greatest benefit so far.\n\
6) More active throughout the day, which helps me to focus more on work.\n\
7) Life satisfaction, Positive thinking and Confidence level got increased.\n\
8) 1-2 hours of meditation noticed crown and third eye chakra areas got more vibrations, tingling Sensation and acupuncture treatment from cosmic energy.\n\
9) 2-6 hours of meditation noticed Throat and Heart Chakra vibrations aswell, feeling like energy flowing through.\n \
10) 6-12 hours of meditation noticed most of the bottom Chakras vibrations aswell and breath rate completely reduced.\n\
11) I feel more vibration and sensation on chakra areas while sitting under Large Pyramid or Hanging Pyramid.\n\
12) Overall I feel like sitting under the Pyramids Meditation enhance the absorption of more cosmic energy to the body,\n\
 which supports better physical health, mental clarity, and increased vitality..",
    image: require("./ContactPhotos/sreeni.jpg"),
  },
];

export default TestimonialsDetails;
