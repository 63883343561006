import React from "react";

import youtube from "../images/youtube.png";
import fb from "../images/Instagram_icon.png";
import newsletter from "../images/Newsletter.png";
function SocialMedia() {
  return (
    <div className="social-media-container">
      <hr />
      <div className="social-media">
        <p className="social-media-text">Connect with us here</p>
        <div className="social-media-icons-block">
          <div className="social-media-icon">
            <a href="https://www.youtube.com/@PMCUSA">
              <img className="yt-icon" src={youtube} alt="youtube-img" />
            </a>
            <p>PMC USA</p>
          </div>
          <div className="vl-social-media"></div>
          <div className="social-media-icon">
            <a href="https://www.instagram.com/atlpma/">
              <img className="fb-icon" src={fb} alt="youtube-img" />
            </a>
            <p>Atlanta PMA</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="newsletter-container">
        <a href="https://bit.ly/subscribe2apma">
          <img className="newsletter" src={newsletter} alt="" />
        </a>
      </div>
    </div>
  );
}

export default SocialMedia;
